import { React, useState, useEffect } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
// import Footer from './components/Footer'
import Page from './Page'
// import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import './App.css'

export default function App() {

  // const [nav, setNav] = useState(false);
  const [top, settop] = useState(true);

  // const handleNav = () => {
  //   setNav(!nav);
  // };

  useEffect(() => {
    window.onscroll = () => { window.pageYOffset >= 690 ? settop(false) : settop(true) }

    // console.log(window.pageYOffset)

    // window.pageYOffset === 0 && console.log("back at top");

    return () => (window.onscroll = null);
  }, []);

  // const handleScroll=()=>{
  //   settop(!top)
  // }


  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });

  // }, [handleScroll]);

  return (
    <>

      <div className='flex justify-between items-center px-4 text-white'>
        <div className='hidden h-24 mx-auto pt-6 px-4 w-full  md:flex '>
          <h1 className={top ? 'w-full fixed text-3xl font-bold md:text-white sm:text-white ' : 'w-full fixed text-3xl font-bold md:text-gray-500 sm:text-white '}>Yabez Silva</h1>

          <ul className={top ? 'hidden md:flex right-20 fixed' : '   hidden md:flex fixed right-20 text-gray-500'}>

            <AnchorLink href='#home' ><li className='p-4 font-bold hover:text-gray-800 active:text-gray-400'>Home</li></AnchorLink>
            <AnchorLink href='#about' ><li className='p-4 font-bold  hover:text-gray-800 active:text-gray-400'>About</li></AnchorLink>
            <AnchorLink href='#portfolio' ><li className='p-4 font-bold  hover:text-gray-800 active:text-gray-400'>Portfolio</li></AnchorLink>
            <AnchorLink href='#contact' ><li className='p-4 font-bold  hover:text-gray-800 active:text-gray-400'>Contact</li></AnchorLink>

          </ul>
        </div>
        {/* <div onClick={handleNav} className='fixed right-4 top-4 justify-center md:hidden hover:cursor-pointer '>
          {nav ? <AiOutlineClose size={20} color='white' /> : <AiOutlineMenu size={20} color='white' />}
        </div>
        <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
          <h1 className='w-full text-3xl font-bold text-gray-500 m-4'>YABEZ</h1>
          <li className='p-4 border-b border-gray-600'>Home</li>
          <li className='p-4 border-b border-gray-600'>Company</li>
          <li className='p-4 border-b border-gray-600'>Resources</li>
          <li className='p-4 border-b border-gray-600'>About</li>
          <li className='p-4'>Contact</li>
        </ul> */}
      </div>




      <Page />

      {/* <Footer /> */}

    </>
  )
}



