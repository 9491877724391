import React from 'react';
import {
  // FaDribbbleSquare,
  // FaFacebookSquare,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTwitterSquare,
} from 'react-icons/fa';

const Newsletter = () => {
  return (
    <div className='w-full text-center justify-center py-4 px-4'>
      <div className='py-4' >

        <div className='lg:grid-cols-3'>
          <div className='lg:col-span-2 my-4'>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
              Let's Get To Know Each Other Better.
            </h1>
            {/* <p>Sign up to our newsletter and stay up to date.</p> */}
            <div className='my-4 justify-items-center'>
              <div className=' justify-center justi'>


                <a href="mailto:mackyabez@gmail.com">

                  <button className=' bg-gray-800 border-4 border-gray-800 hover:bg-transparent hover:text-gray-800 hover:border-gray-800 transition-colors text-white rounded-md font-medium w-[200px] ml-6 my-6 mx-6 px-6 py-2'>
                    Mail
                  </button>
                </a>
              </div>
              {/* <p>
              We care about the protection of your data. Read our{' '}
              <span className='text-gray-500'>Privacy Policy.</span>
            </p> */}

            </div>

          </div>

        </div>

      </div>
      <ul className='flex flex-row justify-center'>
        <li className='px-2'>
          <a href='https://www.instagram.com/mack.yabez/'>
            <FaInstagram size={30} className='hover:fill-gray-600' ></FaInstagram>
          </a>
        </li>
        <li className='px-2'>
          <a href='https://github.com/yabez-mack'>
            <FaGithub size={30} className='hover:fill-gray-600' />
          </a>
        </ li>
        <li className='px-2'>
          <a href='https://www.linkedin.com/in/yabez-silva-981502172/'>
            <FaLinkedin size={30} className='hover:fill-gray-600' />
          </a>
        </ li>
        <li className='px-2'>
          <a href='https://twitter.com/MackYabez'>
            <FaTwitterSquare size={30} className='hover:fill-gray-600' />
          </a>
        </ li>
      </ul>

    </div>
  );
};

export default Newsletter;