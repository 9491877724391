import React from 'react';
import Typed from 'react-typed';
import './Front.css'

const Front = () => {
  return (
    <>

      <div className='text-white h-screen bg-gray-900'>
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
      
          <h1 className='text-white md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
            Yabez Silva
          </h1>
          <div className='flex justify-center items-center text-gray-500'>
            <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
              Full Stack Web Developer          </p>
          </div>

          <Typed
            className=' text-white text-center md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 '
            strings={['React Js', 'MongoDB', 'Node.js']}
            typeSpeed={140}
            backSpeed={160}
            loop
          />
        
        </div>
      </div>
    </>
  );
};

export default Front;