import React from 'react';
// import Laptop from '../assets/laptop.jpg';

const Projects = () => {
  return (
    <div className=' bg-gray-100 w-full py-32 px-4'>

      <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold px-24 py-2'>What I Do</h1>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 py-16'>
        <div className='mx-6'>

          <img className='w-[250px] h-[140px] mx-auto my-4' src={'https://www.ecured.cu/images/thumb/a/a6/React-logo.png/1200px-React-logo.png'} alt='/' />
          <h1 className='md:text-2xl  sm:text-3xl text-2xl px-2 font-bold py-4'>Front-End</h1>

          <p>

            <li>
              Write application interface code with JavaScript
            </li>
            <li>
              Create data visualization tools, libraries, and reusable code for prospects
            </li>
            <li>
              Translate wireframes and designs into good quality code
            </li>
            <li>
              Optimize components to work seamlessly across different browsers and devices
            </li>
            <li>
              Develop responsive web-based UI
            </li>

          </p>
        </div>
        <div className='mx-6'>

          <img className='w-[250px] h-[140px] mx-auto my-4' src={'https://colorlib.com/wp/wp-content/uploads/sites/2/nodejs-frameworks.png'} alt='/' />
          <h1 className='md:text-2xl sm:text-3xl text-2xl px-2 font-bold py-4'>Back-End</h1>

          <p>

            <li>
              Writing reusable, testable, and efficient code
            </li>
            <li>
              Design and implementation of low-latency, high-availability, and performant applications
            </li>
            <li>
              Integration of user-facing elements developed by front-end developers with server side logic
            </li>
            <li>
              Integration of data storage solutions
            </li>
            <li>
            Troubleshoot and debug applications
            </li>


          </p>
        </div>
        <div className='mx-6'>

          <img className='w-[250px] h[140px] mx-auto my-4' src={'https://tse1.mm.bing.net/th?id=OIP.5ie_530evQTqdFUDwv4U6wHaEE&pid=Api&P=0'} alt='/' />
          <h1 className='md:text-2xl  sm:text-3xl text-2xl px-2 font-bold py-4'>Database</h1>
          <p>

            <li>
            Building database systems of high availability and quality depending on each end user’s specialized role
            </li>
            {/* <li>
              Produce an effective database system according to need and specifications
            </li> */}
            <li>
              Proper operations of a database and to ensure that it’s error-free
            </li>
          <li>

            Designing and implementing databases in accordance to end users information needs and views
          </li>
           
            <li>
            Defining users and enabling data distribution to the right user
            {/*  , in appropriate format and in a timely manner */}
            </li>
       

          </p>
        </div>
      </div>
    </div>
  );
};

export default Projects;