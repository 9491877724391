import React from 'react'
import Projects from './components/Projects'
import About from './components/About'
import Front from './components/Front'
import Newsletter from './components/Newsletter'

const Home = () => {

    // settop(true)
    return (
        <div id='home'>
            <Front />
        </div>
    )
}

const Aboutme = () => {

    // settop(false) 
    return (
        <div id='about'>
            <About />
        </div>
    )
}

const Portfolio = () => {

    return (
        <div id='portfolio'>
            <Projects />
        </div>
    )
}

const Contact = () => {

    return (
        <div id='contact'>
            <Newsletter />
        </div>
    )
}

const Page = () => {

    return (
        <div>
            <Home />
            <Aboutme />
            <Portfolio />
            <Contact />
        </div>
    )
}


export default Page